import React from 'react';
import styles from './ModalWithChildren.module.scss';

interface ModalWithChildrenProps {
    isOpen: boolean;
    onClose: () => void;
    title?: string;
    children: React.ReactNode;
}

const ModalWithChildren: React.FC<ModalWithChildrenProps> = ({
    isOpen,
    onClose,
    title,
    children
}) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalBackdrop} onClick={onClose}>
            <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
                {title && <div className={styles.modalHeader}>{title}</div>}
                <div className={styles.modalBody}>
                    {children}
                </div>
            </div>
        </div>
    );
};

export default ModalWithChildren;
