import React from 'react';
import styles from './InputCheckboxUI.module.scss';

type InputCheckboxProps = {
  className?: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: boolean;
};

const InputCheckbox: React.FC<InputCheckboxProps> = ({ className, id, onChange, value }) => {
  return (
    <input
      type='checkbox'
      className={`${styles.checkbox} ${className || ''}`}
      id={id}
      onChange={onChange}
      checked={value}
    />
  );
};

export default InputCheckbox;
