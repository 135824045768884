import React from 'react';
import styles from './SliderSwitch.module.scss';
import { useUpdateBidSandboxMutation } from '../../../services/api';

interface SliderSwitchProps {
    bidId: string;
    initialSandbox?: boolean;
    onChange?: (isChecked: boolean) => void;
}

const SliderSwitch: React.FC<SliderSwitchProps> = ({ bidId, initialSandbox = false, onChange }) => {
    const [isChecked, setIsChecked] = React.useState(initialSandbox);
    const [updateSandbox] = useUpdateBidSandboxMutation();

    const handleChange = async (newValue: boolean) => {
        setIsChecked(newValue);

        try {
            await updateSandbox({ id: bidId, sandbox: newValue }).unwrap();
            onChange?.(newValue);
        } catch (error) {
            console.error('Ошибка при обновлении sandbox статуса:', error);
            setIsChecked(!newValue); // Возвращаем предыдущее состояние в случае ошибки
        }
    };

    return (
        <div className={styles.sliderContainer}>
            <div
                className={`${styles.option} ${!isChecked ? styles.active : ''}`}
                onClick={() => handleChange(false)}
            >
                ЗАЯВКА
            </div>
            <div
                className={`${styles.option} ${isChecked ? styles.active : ''}`}
                onClick={() => handleChange(true)}
            >
                ОБУЧЕНИЕ
            </div>
        </div>
    );
};

export default SliderSwitch;
