import React, { useMemo, useState } from 'react';
import { useFetchOffersQuery } from 'services/api';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import LoadingOverlay from 'components/ui/LoadingOverlay/LoadingOverlay';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import { OptionType } from '../model/types';
import CategorySelect from 'components/ui/CategorySelect/CategorySelect';
import Table from 'components/ui/Table/ui/Table';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import FiltersIcon from 'assets/icons/FiltersIcon';
import { MobileFilter } from 'components/ui/MobileFilter';

import styles from './OffersList.module.scss';
import { useNavigate } from 'react-router-dom';

const OffersList = () => {
    const { data: offers = [], error, isLoading } = useFetchOffersQuery({ page: 1 });
    // console.log("🚀 ~ OffersList ~ offers:", offers)
    // /static/logos/alfabank.svg
    const navigate = useNavigate();

    const isMobile = useMediaQuery(768);
    const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна

    const [searchTerm, setSearchTerm] = useState('');
    const [selectedCategory, setSelectedCategory] = useState('');
    const [selectedOffer, setSelectedOffer] = useState<OptionType | null>(null);
    const [resetSelect, setResetSelect] = useState(false);

    const handleApplicationClick = (offerId: number) => {
        navigate(`/offers/${offerId}`);
    };

    // Определяем колонки для таблицы
    const columns = useMemo(() => [
        {
            accessorKey: 'title',
            header: 'Оффер',
            cell: ({ row }: any) => (
                <div className={styles.offerCell}>
                    <div className={styles.offerCellImage}>
                        <img src={row.original.logo} alt={row.original.title} width="50" height="50" />
                    </div>
                    <span>{row.original.title}</span>
                </div>
            ),
        },
        {
            accessorKey: 'full_categories',
            header: 'Категории',
            cell: ({ row }) => (
                <span>{row.original.full_categories?.map((cat: any) => cat.title).join(', ')}</span>
            ),
        },
        {
            accessorKey: 'actions',
            header: '',
            cell: ({ row }) => (
                <div className={styles.tableCellActions}>
                    <ButtonUI label="ОТПРАВИТЬ ЗАЯВКУ" variant="main2" height="42px" onClick={() => handleApplicationClick(row.original.id)} />
                </div>
            ),
        }

    ], []);

    // Фильтрация данных
    const filteredOffers = useMemo(() => {
        let filtered = offers;

        if (searchTerm) {
            filtered = filtered.filter((offer: any) =>
                offer.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                offer.id.toString().includes(searchTerm) || // Поиск по числовому ID
                offer.offer_id?.toLowerCase().includes(searchTerm.toLowerCase()) // Поиск по строковому offer_id
            );
        }

        if (selectedOffer) {
            filtered = filtered.filter((offer: any) => offer.id === selectedOffer?.value);
        }

        if (selectedCategory) {
            filtered = filtered.filter((offer: any) =>
                offer.full_categories?.some((category: { id: string }) => category.id === selectedCategory)
            );
        }

        return filtered;
    }, [searchTerm, selectedOffer, selectedCategory, offers]);
    // console.log("🚀 ~ filteredOffers ~ filteredOffers:", filteredOffers)

    const handleReset = () => {
        setSearchTerm('');
        setSelectedCategory('');
        setSelectedOffer(null);
        setResetSelect(true);
    };

    const handleOfferSelect = (offer: any) => {
        setSelectedOffer(offer);
        setResetSelect(false);
    };

    const renderCardView = () => (
        <div className={styles.cardsContainer}>
            {filteredOffers.map((offer: any) => (
                <div key={offer.id} className={styles.card}>
                    <div className={styles.cardRow}>
                        <div className={styles.cardLogo}>
                            <img src={offer.logo} alt={offer.title} width="50" height="50" />
                        </div>
                        <div className={styles.cardContent}>
                            <TextUI variant="h2">{offer.title}</TextUI>
                            <span>{offer.full_categories?.map((cat: any) => cat.title).join(', ')}</span>
                        </div>
                    </div>
                    <ButtonUI 
                        label="ОТПРАВИТЬ ЗАЯВКУ" 
                        variant="main2" 
                        height='42px' 
                        width='186px'
                        onClick={() => handleApplicationClick(offer.id)}
                    />
                </div>
            ))}
        </div>
    );

    if (isLoading) return <LoadingOverlay />;
    if (error) return <div>Ошибка при загрузке офферов</div>;

    return (
        <div className={styles.offersList}>
            <TextUI variant="h1" className={styles.marginBottom}>Офферы</TextUI>
            <div className={styles.offersFiltersNumbersBox}>
                {isMobile && (
                    <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
                        <FiltersIcon />
                    </div>
                )}
                {isMobile && filteredOffers.length > 0 && <div className={styles.badge}>{filteredOffers.length}</div>}

            </div>



            {/* Модалка для фильтров на мобильной версии */}
            {isMobile && isModalOpen && (
                <MobileFilter
                    title="Параметры"
                    onClose={() => setIsModalOpen(false)}
                    onShow={() => setIsModalOpen(false)}
                    onReset={handleReset}
                    className={styles.offerMobileFilter}
                    filterBtnClassName={styles.offerBtnMobileFilter}
                >
                    <CategorySelect
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        offers={offers}
                    />
                </MobileFilter>
            )}

            <div className={styles.filters}>
                <div className={styles.twoFilters}>
                    <SingleSelectSearch
                        options={offers.map((offer: any) => ({
                            label: offer.title + `${', '}` + offer.full_categories
                            [0].title, value: offer.id
                        }))}
                        onOptionSelect={handleOfferSelect}
                        placeholder="Поиск по офферу"
                        reset={resetSelect}
                        title="Поиск"
                    />
                    {!isMobile && <CategorySelect
                        selectedCategory={selectedCategory}
                        setSelectedCategory={setSelectedCategory}
                        offers={offers}
                    />}

                </div>

                <div className={`${styles.filterButtons} ${styles.offerFilterButtons}`}>
                    <ButtonUI label="Сбросить фильтр" variant="main2" height='42px' onClick={handleReset} />
                </div>
            </div>


            {/* Отображаем таблицу или карточки в зависимости от ширины экрана */}
            {isMobile ? renderCardView() : <Table data={filteredOffers} columns={columns} />}
        </div>
    );
};

export default OffersList;
