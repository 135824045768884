import React from 'react'
import OfferDetails from 'components/OfferDetails/ui/OfferDetails'
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout'

export default function OfferDetailsPage() {
    return (
        <MainAppLayout>
            <OfferDetails />
        </MainAppLayout>
    )
}
