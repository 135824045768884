import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
// import authReducer from '../features/register/authSlice';
import loginReducer from '../features/login/loginSlice';
import requestReducer from 'features/request/requestSlice';
import { apiSlice } from 'services/api';

// Combine reducers
const rootReducer = combineReducers({
  // auth: authReducer,
  login: loginReducer,
  request: requestReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// Configure persistence
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['login', 'request', apiSlice.reducerPath], // Add request reducer to persistence
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with persisted reducer
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }).concat(apiSlice.middleware),
});

// Create persistor
export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
