import React from 'react';
import styles from './Modal.module.scss';
import ErrorIcon from 'assets/icons/ErrorIcon';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    message: string;
    icon?: React.ReactNode;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, message, icon }) => {
    if (!isOpen) return null;

    return (
        <div className={styles.modalOverlay}>
            <div className={styles.modalContent}>
                {/* className={styles.errorIcon}  */}
                {icon ? icon : <ErrorIcon />}
                <p className={styles.message}>{message}</p>
                <button onClick={onClose} className={styles.okButton}>
                    OK
                </button>
            </div>
        </div>
    );
};

export default Modal;
