import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useReactTable, getCoreRowModel, getPaginationRowModel, flexRender } from '@tanstack/react-table';
import Accepted from '../../../../assets/icons/accepted.svg'
import Refuse from '../../../../assets/icons/refuse.svg'
import NotDone from '../../../../assets/icons/not-done.svg'
import InfoIcon from '../../../../assets/icons/info.svg';
import Tooltip from 'components/ui/Tooltip/ui/Tooltip';

import styles from './Table.module.scss'; // Стили для таблицы

interface TableProps {
    columns: any[];  // Массив колонок
    data: any[];     // Данные для отображения
    showTitleRow?: boolean; // Проп для показа строки с текстом "Заявки"
    tablePageSize?: number;
}

const renderTitleRow = (pageType: string, columns: any[], styles: any) => {
    switch (pageType) {
        case '/daily-statistic':
            return (
                <tr className={styles.titleRow}>
                    <th className={styles.firstColumnSeparator}></th> {/* Пустой первый столбец для /daily-statistic */}
                    <th colSpan={columns.length - 1} className={styles.titleCell}>
                        Заявки
                        <Tooltip
                            content={
                                <div className={styles.tooltipWrapper}>
                                    <div className={styles.tooltipRow}>
                                        <span className={styles.statusIndicator}>
                                            <span className={`${styles.statusDot} ${styles.statusApproved}`} />
                                            <span>Одобрено</span>
                                        </span>
                                        <span>- заявка одобрена банком</span>
                                    </div>
                                    <div className={styles.tooltipRow}>
                                        <span className={styles.statusIndicator}>
                                            <span className={`${styles.statusDot} ${styles.statusRejected}`} />
                                            <span>Отказано</span>
                                        </span>
                                        <span>- заявка отклонена банком</span>
                                    </div>
                                    <div className={styles.tooltipRow}>
                                        <span className={styles.statusIndicator}>
                                            <span className={`${styles.statusDot} ${styles.statusPending}`} />
                                            <span>Не завершена</span>
                                        </span>
                                        <span>- ваша заявка не оформлена до конца</span>
                                    </div>
                                </div>
                            }
                        >
                            <img src={InfoIcon} alt="info" className={styles.infoIcon} />
                        </Tooltip>
                    </th>
                </tr>
            );

        case '/statistic-by-offers':
            return (
                <tr className={styles.titleRow}>
                    <th></th> {/* Пустой первый столбец */}
                    <th className={styles.firstColumnSeparator}></th> {/* Разделительная линия */}
                    <th colSpan={columns.length - 2} className={styles.titleCell}>Заявки</th>
                </tr>
            );

        default:
            return null; // Возвращаем null, если не нужно отображать заголовок
    }
};

const Table: React.FC<TableProps> = ({ columns, data, showTitleRow = false, tablePageSize = 3, }) => {
    const location = useLocation(); // Получаем текущий URL
    const pageType = location.pathname; // Определяем тип страницы
    const [pagination, setPagination] = useState({
        pageIndex: 0,  // Начальная страница
        pageSize: tablePageSize,  // Количество строк на странице
    });

    const table = useReactTable({
        data,
        columns,
        pageCount: Math.ceil(data.length / pagination.pageSize),
        state: { pagination },
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        onPaginationChange: setPagination,
        manualPagination: false,  // Используем клиентскую пагинацию
    });

    return (
        <>
            <div className={styles.tableContainer}>
                {table.getRowModel().rows.length === 0 ? (
                    <div className={styles.noDataMessage}>Данные не найдены</div>
                ) : (
                    <table className={styles.table}>
                        <thead>
                            {showTitleRow && renderTitleRow(pageType, columns, styles)}
                            {table.getHeaderGroups().map(headerGroup => (
                                <tr key={headerGroup.id}>
                                    {headerGroup.headers.map(header => (
                                        <th
                                            key={header.id}
                                            className={
                                                (pageType === '/daily-statistic' && header.column.id === 'date') ||
                                                    (pageType === '/statistic-by-offers' && header.column.id === 'offerName')
                                                    ? styles.withSeparator
                                                    : ''
                                            }
                                        >
                                            {header.isPlaceholder
                                                ? null
                                                : flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map(row => (
                                <tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <td
                                            key={cell.id}
                                            className={
                                                (pageType === '/daily-statistic' && cell.column.id === 'date') ||
                                                    (pageType === '/statistic-by-offers' && cell.column.id === 'offerName')
                                                    ? styles.withSeparator
                                                    : ''
                                            }
                                        >
                                            {cell.column.id === 'status' && cell.getValue() === 'approved' || cell.column.id === 'paymentStatus' && cell.getValue() === 'paid' ? (
                                                <>
                                                    <img
                                                        src={Accepted}
                                                        alt="accepted"
                                                        className={styles.acceptedIcon}
                                                    />
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </>
                                            ) : cell.column.id === 'status' && cell.getValue() === 'draft' ? (
                                                <>
                                                    <img
                                                        src={NotDone}
                                                        alt="NotDone"
                                                        className={styles.acceptedIcon}
                                                    />
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </>
                                            ) : cell.column.id === 'paymentStatus' && cell.getValue() === 'unpaid' ? (
                                                <>
                                                    <img
                                                        src={Refuse}
                                                        alt="Refuse"
                                                        className={styles.acceptedIcon}
                                                    />
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </>
                                            ) : cell.column.id === 'status' && cell.getValue() === 'rejected' ? (
                                                <>
                                                    <img
                                                        src={Refuse}
                                                        alt="Refuse"
                                                        className={styles.acceptedIcon}
                                                    />
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </>
                                            ) : cell.column.id === 'paymentStatus' && cell.getValue() === 'pending' ? (
                                                <>
                                                    <img
                                                        src={NotDone}
                                                        alt="NotDone"
                                                        className={styles.acceptedIcon}
                                                    />
                                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                                </>
                                            )
                                                :
                                                (
                                                    flexRender(cell.column.columnDef.cell, cell.getContext())
                                                )
                                            }
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                )}
            </div>
            {table.getPageCount() > 1 && (
                <div className={styles.pagination}>
                    <button
                        className={!table.getCanPreviousPage() ? styles.disabledArrow : styles.arrow}
                        onClick={() => table.setPageIndex(0)}  // Перейти на первую страницу
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<<'}
                    </button>
                    <button
                        className={!table.getCanPreviousPage() ? styles.disabledArrow : styles.arrow}
                        onClick={() => table.previousPage()}  // Перейти на предыдущую страницу
                        disabled={!table.getCanPreviousPage()}
                    >
                        {'<'}
                    </button>

                    {[...Array(table.getPageCount())].map((_, i) => (
                        <button
                            key={i}
                            onClick={() => table.setPageIndex(i)}
                            className={table.getState().pagination.pageIndex === i ? styles.selected : styles.default}
                        >
                            {i + 1}
                        </button>
                    ))}

                    <button
                        className={!table.getCanNextPage() ? styles.disabledArrow : styles.arrow}
                        onClick={() => table.nextPage()}  // Перейти на следующую страницу
                        disabled={!table.getCanNextPage()}
                    >
                        {'>'}
                    </button>
                    <button
                        className={!table.getCanNextPage() ? styles.disabledArrow : styles.arrow}
                        onClick={() => table.setPageIndex(table.getPageCount() - 1)}  // Перейти на последнюю страницу
                        disabled={!table.getCanNextPage()}
                    >
                        {'>>'}
                    </button>
                </div>
            )}
        </>
    );
};

export default Table;
