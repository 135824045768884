import React from 'react';

const PencilIcon = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1510_4542)">
        <path d="M19.2686 6.03602L18.3847 6.92036L13.0807 1.61708L13.9646 0.732654C14.941 -0.243893 16.5244 -0.243893 17.5009 0.732654L19.2686 2.5008C20.2437 3.47681 20.2437 5.05956 19.2686 6.03602ZM6.89308 16.6419C6.64898 16.886 6.64898 17.2814 6.89308 17.5255C7.1373 17.7709 7.53283 17.7709 7.77693 17.5255L17.5009 7.80417L16.616 6.91974L6.89308 16.6419ZM2.47311 12.223C2.22889 12.4673 2.22889 12.8625 2.47311 13.1067C2.7172 13.3508 3.11273 13.3508 3.35695 13.1067L13.0807 3.38465L12.1971 2.5008L2.47311 12.223ZM13.9634 4.26783L4.24059 13.9905C3.75236 14.4778 3.7536 15.2699 4.24059 15.7582C4.72903 16.2464 5.51993 16.2487 6.00944 15.7569L15.7323 6.03602L13.9634 4.26783ZM6.00692 18.4071C5.70797 18.1077 5.56387 17.7284 5.51024 17.3375C5.38317 17.3572 5.25518 17.3754 5.12444 17.3754C4.45662 17.3754 3.8294 17.1144 3.35695 16.6419C2.88451 16.1683 2.6245 15.5419 2.6245 14.8742C2.6245 14.7524 2.64276 14.6338 2.65991 14.5155C2.25584 14.4604 1.88236 14.2835 1.58938 13.9905C1.56127 13.9626 1.55158 13.9247 1.52599 13.8941L0 19.9999L6.08994 18.4754C6.06311 18.451 6.03273 18.4327 6.00692 18.4071Z" fill="#AAAABB" />
        <path d="M18.3861 6.92036L19.27 6.03602C20.2451 5.05956 20.2451 3.47681 19.27 2.5008L17.5023 0.732654C16.5258 -0.243893 14.9424 -0.243893 13.966 0.732654L13.082 1.61708L18.3861 6.92036Z" fill="#71BE81" />
        <path d="M5.51024 17.3375C5.56387 17.7283 5.70797 18.1077 6.00692 18.407C6.03273 18.4326 6.06311 18.4509 6.08994 18.4753L0 19.9999L1.52599 13.894C1.55158 13.9246 1.56127 13.9625 1.58938 13.9904C1.88236 14.2834 2.25584 14.4604 2.65991 14.5154C2.64276 14.6337 2.6245 14.7524 2.6245 14.8741C2.6245 15.5419 2.88451 16.1682 3.35695 16.6419C3.8294 17.1143 4.45662 17.3753 5.12444 17.3753C5.25518 17.3753 5.38317 17.3571 5.51024 17.3375Z" fill="#71BE81" />
      </g>
      <defs>
        <clipPath id="clip0_1510_4542">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PencilIcon;
