import React from 'react';
import styles from './ProductCard.module.scss';

interface ProductCardProps {
    label: string;
    value: string;
    selectedValue: string;
    onChange: (value: string) => void;
    img?: string;
    name: string; // Проп для имени группы радио-кнопок
}

const ProductCard: React.FC<ProductCardProps> = ({ label, value, selectedValue = '', onChange, img, name }) => {
    return (
        <label className={`${styles.productOption} ${selectedValue === value ? styles.selected : ''}`}>
            {img && <div className={styles.offerCellImage}>
                <img src={img} alt={label} className={styles.bankImage} />
            </div>}
            <span>{label}</span>
            <input
                type="radio"
                name={name}
                value={value}
                checked={selectedValue === value} // Связываем с выбранным значением
                onChange={() => onChange(value)}
                className={styles.radioInput}
            />
        </label>
    );
};

export default ProductCard;
