import React from 'react';
import Select, { components } from 'react-select';
import styles from './CustomSelect.module.scss';

type Option = {
    value: string;
    label: string;
};

type CustomSelectProps = {
    selectedValue: string;
    setSelectedValue: (value: string) => void;
    options: { id: string; title: string }[];
    label?: string;
    placeholder?: string;
    error?: string;
};

const Control = ({ children, ...props }: any) => (
    <components.Control {...props} className={`${props.className} ${styles.control} ${props.isFocused ? styles.focused : ''} ${props.selectProps.error ? styles.error : ''}`}>
        {children}
    </components.Control>
);

const Option = ({ children, ...props }: any) => (
    <components.Option {...props} className={`${props.className} ${styles.option}`}>
        {children}
    </components.Option>
);

const CustomSelect: React.FC<CustomSelectProps> = ({
    selectedValue,
    setSelectedValue,
    options,
    label = 'Выберите категорию',
    placeholder = 'Все',
    error,
}) => {
    const selectOptions: Option[] = options.map(option => ({
        value: option.id,
        label: option.title.toLocaleUpperCase()
    }));

    const handleChange = (selectedOption: Option | null) => {
        setSelectedValue(selectedOption?.value || '');
    };

    return (
        <div className={styles.inputWrapper}>
            <Select
                value={selectOptions.find(option => option.value === selectedValue)}
                onChange={handleChange}
                options={selectOptions}
                placeholder={placeholder.toLocaleUpperCase()}
                isSearchable={false}
                classNamePrefix="select"
                className={styles.reactSelect}
                components={{ Control, Option }}
            />
            {error && <span className={styles.errorMessage}>{error}</span>}
        </div>
    );
};

export default CustomSelect;
