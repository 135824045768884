import React, { useState } from 'react';
import { SMSCodeForm } from 'components/SmsCodeForm/SmsCodeForm';
import styles from './Step5.module.scss';

interface Step5Props {
    setIsFormValid: (isValid: boolean) => void;
    handleFormSubmit: () => void;
    requestId: string;
    smsError: string | null;
}

const Step5: React.FC<Step5Props> = ({ setIsFormValid, handleFormSubmit, requestId, smsError }) => {
    const [isVerified, setIsVerified] = useState(false);

    const handleSmsSubmit = (code: string, success: boolean) => {
        setIsVerified(success);
        setIsFormValid(success);
    };

    return (
        <div className={styles.container}>
            {smsError && (
                <div className={styles.errorMessage}>
                    <div>Ошибка при отправке SMS: {smsError}</div>
                    <div>Пожалуйста, проверьте ваш номер телефона на Шаге 1</div>
                </div>
            )}
            <SMSCodeForm
                onSubmit={handleSmsSubmit}
                requestId={requestId}
                isVerified={isVerified}
            />
        </div>
    );
};

export default Step5;
