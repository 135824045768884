// src/store/slices/requestSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RequestState {
    currentStep: number;
    status: 'draft' | 'send' | 'pending' | 'approved' | 'rejected';
    data: {
        selectedBank: string;
        selectedProduct: string;
        personalData: any;
        additionalData: any;
        requestedAmount: number;
        documentPhotos: string[]; // массив путей для фото документов
        clientPhoto: string | null;
        partnerResponseMessage?: string; // Добавляем новое поле
        partnerResponseCode?: string; // Добавляем новое поле для кода ответа
    };
}

const initialState: RequestState = {
    currentStep: 0,
    status: 'draft', // По умолчанию заявка в статусе черновика
    data: {
        selectedBank: '',
        selectedProduct: '',
        personalData: {},
        additionalData: {},
        requestedAmount: 90000,
        documentPhotos: [], // инициализация пустым массивом
        clientPhoto: null,
        partnerResponseMessage: '', // Инициализируем пустой строкой
        partnerResponseCode: '', // Инициализируем пустой строкой
    },
};

export const requestSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {
        setCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },
        setStatus: (state, action: PayloadAction<RequestState['status']>) => {
            state.status = action.payload;
        },
        setSelectedBank: (state, action: PayloadAction<string>) => {
            state.data.selectedBank = action.payload;
        },
        setSelectedProduct: (state, action: PayloadAction<string>) => {
            state.data.selectedProduct = action.payload;
        },
        savePersonalData: (state, action: PayloadAction<any>) => {
            state.data.personalData = action.payload;
        },
        saveAdditionalData: (state, action: PayloadAction<any>) => {
            state.data.additionalData = action.payload;
        },
        setRequestedAmount: (state, action: PayloadAction<number>) => {
            state.data.requestedAmount = action.payload; // Сохраняем сумму кредита
        },
        setDocumentPhotos: (state, action: PayloadAction<string[]>) => {
            state.data.documentPhotos = action.payload;
        },
        addDocumentPhoto: (state, action: PayloadAction<string>) => {
            state.data.documentPhotos.push(action.payload);
        },
        removeDocumentPhoto: (state, action: PayloadAction<string>) => {
            state.data.documentPhotos = state.data.documentPhotos.filter(photo => photo !== action.payload);
        },
        setClientPhoto: (state, action: PayloadAction<string | null>) => {
            state.data.clientPhoto = action.payload;
        },
        setPartnerResponseMessage: (state, action: PayloadAction<string>) => {
            state.data.partnerResponseMessage = action.payload;
        },
        setPartnerResponseCode: (state, action: PayloadAction<string>) => {
            state.data.partnerResponseCode = action.payload;
        },
        clearPartnerResponse: (state) => {
            state.data.partnerResponseMessage = '';
            state.data.partnerResponseCode = '';
        },
    },
});

export const {
    setCurrentStep,
    setStatus,
    setSelectedBank,
    setSelectedProduct,
    savePersonalData,
    saveAdditionalData,
    setRequestedAmount,
    setDocumentPhotos,
    addDocumentPhoto,
    removeDocumentPhoto,
    setClientPhoto,
    setPartnerResponseMessage,
    setPartnerResponseCode,
    clearPartnerResponse,
} = requestSlice.actions;

export default requestSlice.reducer;