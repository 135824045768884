import React, { useMemo, useState, useEffect } from 'react';
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import Table from 'components/ui/Table/ui/Table';
import CustomCalendarSelect from 'components/ui/CustomCalendarSelect/ui/CustomCalendarSelect';
import { SingleSelectSearch } from 'components/ui/SingleSelectSearch/ui/SingleSelectSearch';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { OptionType } from 'components/Offers/model/types';
import { Offer, StatisticItem } from 'shared/models';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery'; // Для определения мобильной версии
import FiltersIcon from 'assets/icons/FiltersIcon'; // Иконка фильтров
import { MobileFilter } from 'components/ui/MobileFilter';
import { useFetchDailyStatisticQuery, useFetchOffersQuery, useGetApplicationsQuery } from 'services/api';

import styles from './DailyStatistic.module.scss';

interface Category {
  id: string;
  title: string;
}

const getDefaultRange = (): [Date, Date] => {
  const today = new Date();
  const startOfYear = new Date(today.getFullYear(), 0, 1);
  return [startOfYear, today];
};

const DailyStatistic = () => {
  // const [filteredData, setFilteredData] = useState<StatisticItem[]>(data);
  const [dateRange, setDateRange] = useState<[Date, Date]>(getDefaultRange()); // Устанавливаем начальный диапазон
  const [selectedOffer, setSelectedOffer] = useState<number | null>(null);
  const [resetSelect, setResetSelect] = useState<boolean>(false);
  const [resetDates, setResetDates] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false); // Состояние для модального окна
  const isMobile = useMediaQuery(768); // Определяем, используется ли мобильная версия

  const { data: statistics = [], error, isLoading } = useFetchDailyStatisticQuery({ page: 1 });
  const { data: offers = [], error: offersError, isLoading: isOffersLoading } = useFetchOffersQuery({ page: 1 });
  const { data: applications = [], isLoading: isApplicationsLoading } = useGetApplicationsQuery({ page: 1 });
  console.log("🚀 ~ DailyStatistic ~ applications:", applications)

  const [filteredData, setFilteredData] = useState<StatisticItem[]>([]);
  // console.log("🚀 ~ DailyStatistic ~ filteredData:", filteredData)

  useEffect(() => {
    if (applications.length > 0) {
      // Group applications by date and count statuses
      const groupedData = applications.reduce((acc: any, app: any) => {
        const date = new Date(app.date).toISOString().split('T')[0];

        // Create a unique key for each date
        if (!acc[date]) {
          acc[date] = {
            date,
            all: 0,
            approved: 0,
            inProgress: 0,
            rejected: 0,
            offers: new Map() // Use Map to track unique offers for this date
          };
        }

        // Track offer information
        const offerKey = `${app.offer.id}-${app.offer.name}-${app.offer.category}`;
        if (!acc[date].offers.has(offerKey)) {
          acc[date].offers.set(offerKey, {
            id: app.offer.id,
            name: app.offer.name,
            category: app.offer.category
          });
        }

        acc[date].all += 1;

        switch (app.status) {
          case 'approved':
            acc[date].approved += 1;
            break;
          case 'rejected':
            acc[date].rejected += 1;
            break;
          default:
            acc[date].inProgress += 1;
        }

        return acc;
      }, {});

      // Convert to array and sort by date
      let statisticsData: any = Object.values(groupedData)
        .map((item: any) => ({
          date: item.date,
          approved: item.approved,
          rejected: item.rejected,
          inProgress: item.inProgress,
          all: item.all,
          offers: Array.from(item.offers.values()) // Convert Map to array
        }))
        .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

      // Apply filters
      if (dateRange) {
        const [startDate, endDate] = dateRange;
        statisticsData = statisticsData.filter((item: StatisticItem) => {
          const itemDate = new Date(item.date);
          return itemDate >= startDate && itemDate <= endDate;
        });
      }

      if (selectedOffer !== null) {
        statisticsData = statisticsData.filter((item: StatisticItem) =>
          item.offers.some(offer => offer.id === selectedOffer)
        );
      }

      setFilteredData(statisticsData);
    }
  }, [applications, dateRange, selectedOffer]);

  // Фильтруем офферы и группируем их по банку и категории
  const filteredOffers = useMemo(() => {
    const offerMap = new Map<string, Offer>();

    applications.forEach(app => {
      const offer = app.offer;
      const key = `${offer.id}`;
      if (!offerMap.has(key)) {
        offerMap.set(key, {
          id: offer.id,
          name: offer.name,
          title: `${offer.name} - ${offer.category}`,
          full_categories: [{
            id: offer.category,
            title: offer.category
          }]
        });
      }
    });

    return Array.from(offerMap.values());
  }, [applications]);

  const offerOptions = useMemo(() => {
    // Используем Map для фильтрации уникальных офферов по ID
    const uniqueOffersMap = new Map<number, Offer>();

    filteredOffers.forEach((offer: Offer) => {
      if (!uniqueOffersMap.has(offer.id)) {
        uniqueOffersMap.set(offer.id, offer);
      }
    });

    const uniqueOffers = Array.from(uniqueOffersMap.values());

    return uniqueOffers.map(offer => ({
      label: offer.title ?? 'Неизвестный оффер', // Убедитесь, что 'label' всегда строка
      value: offer.id,
    }));
  }, [filteredOffers]);

  const columns = useMemo(() => [
    {
      accessorKey: 'date',
      header: 'Дата',
    },
    {
      accessorKey: 'all',
      header: 'Все',
    },
    {
      accessorKey: 'approved',
      header: 'Принято',
    },
    {
      accessorKey: 'inProgress',
      header: 'В работе',
    },
    {
      accessorKey: 'rejected',
      header: 'Отклонено',
    },
  ], []);

  const handleReset = () => {
    setSelectedOffer(null);
    setResetSelect(true);
    setDateRange(getDefaultRange());
    setFilteredData(filteredData);

    setTimeout(() => setResetDates(false), 100);
  };

  // const handleConfirm = () => {
  //   if (dateRange) {
  //     console.log("🚀 ~ handleConfirm ~ ")
  //     setFilteredData(prevData => {
  //       let filtered = data;

  //       const [startDate, endDate] = dateRange;
  //       filtered = filtered.filter(item => {
  //         const itemDate = new Date(item.date);
  //         return itemDate >= startDate && itemDate <= endDate;
  //       });

  //       return filtered;
  //     });
  //   }
  // };

  useEffect(() => {
    const [startDate, endDate] = getDefaultRange();
    setDateRange([startDate, endDate]);
  }, []);

  const handleDateChange = (range: [Date, Date]) => {
    setDateRange(range);
  };

  const handleOfferSelect = (selected: OptionType | null) => {
    if (selected) {
      setSelectedOffer(typeof selected.value === 'string' ? parseInt(selected.value, 10) : selected.value);
    } else {
      setSelectedOffer(null);
    }
    setResetSelect(false);
  };

  return (
    <MainAppLayout>
      <TextUI variant="h1" className={styles.marginBottom}>Cтатистика по дням</TextUI>

      {isMobile ? (
        <>
          {/* Иконка для открытия фильтров на мобильной версии */}
          <div className={styles.filterMenu} onClick={() => setIsModalOpen(true)}>
            <FiltersIcon />
          </div>

          <div className={styles.selectWrapper}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
              title="Поиск"
            />
          </div>

          {/* Модалка для фильтров на мобильной версии */}
          {isModalOpen && (
            <MobileFilter
              title="Параметры"
              onClose={() => setIsModalOpen(false)}
              onShow={() => setIsModalOpen(false)}
              onReset={handleReset}
              className={styles.offerMobileFilter}
              filterBtnClassName={styles.offerBtnMobileFilter}
            >
              <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
            </MobileFilter>
          )}
        </>
      ) : (
        <div className={styles.filters}>
          <div className={styles.twoFilters}>
            <SingleSelectSearch
              options={offerOptions}
              onOptionSelect={handleOfferSelect}
              placeholder="Поиск по офферу"
              reset={resetSelect}
            />
            <CustomCalendarSelect onDateChange={handleDateChange} resetDates={resetDates} dateRange={dateRange} />
          </div>

          {/* <div className={styles.filterButtonShow}>
            <ButtonUI label="Показать" variant="main2" width='108px' height='42px' />
          </div> */}

          <div className={styles.filterButtons}>
            {/* <ButtonUI label="Показать" variant="main" height='42px' onClick={handleConfirm} /> */}
            <ButtonUI label="Сбросить фильтр" variant="main2" height='42px' onClick={handleReset} />
          </div>
        </div>
      )}

      <div className={styles.statistics}>
        <Table columns={columns} data={filteredData} showTitleRow tablePageSize={Number(12)} />
      </div>
    </MainAppLayout>
  );
};

export default DailyStatistic;
