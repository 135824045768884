import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { updateUser } from 'features/login/loginSlice';
import { useUpdateUserMutation } from 'services/api';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import PencilSettings from '../../../../assets/icons/pencil-settings.svg';

import styles from './ProfileForm.module.scss';
import { Modal } from 'components/ui/Modal';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import PencilIcon from 'assets/icons/PencilIcon';

interface ProfileData {
    name: string;
    messenger: string;
    phone: string;
    email: string;
}

const ProfileForm = () => {
    const dispatch = useDispatch();
    const user = useSelector((state: any) => state.login?.user);
    const [updateUserApi, { isLoading, error }] = useUpdateUserMutation();

    const [isUpdated, setIsUpdated] = useState(false);
    // console.log("🚀 ~ ProfileForm ~ isUpdated:", isUpdated)

    const [isModalOpen, setIsModalOpen] = useState(false); // Управление модалкой
    const [modalMessage, setModalMessage] = useState('');

    const isMobile = useMediaQuery(768);

    const methods = useForm<ProfileData>({
        defaultValues: {
            name: user?.name || '',
            messenger: user?.messenger || '',
            phone: user?.phone || '',
            email: user?.email || '',
        },
    });

    const { handleSubmit, watch } = methods;

    // Слежение за изменением значений полей ввода
    const name = watch('name');
    const messenger = watch('messenger');
    const phone = watch('phone');
    const email = watch('email');

    useEffect(() => {
        setIsUpdated(false); // Сбрасываем флаг при изменении значений в инпутах
    }, [name, messenger, phone, email]);

    const onSubmit = async (data: ProfileData) => {
        try {
            const result = await updateUserApi(data).unwrap();
            dispatch(updateUser(result));
            setIsUpdated(true); // Устанавливаем флаг при успешном обновлении

            // Показываем модалку при успешном сохранении на мобильной версии
            if (isMobile) {
                setModalMessage('Ваши изменения были сохранены');
                setIsModalOpen(true);
            }

        } catch (err) {

            const errorMessage = 'Некоторые поля не заполнены или заполнены неверно';

            // Открываем модалку с сообщением об ошибке
            setModalMessage(errorMessage);
            setIsModalOpen(true);

            console.error('Failed to update profile:', err);
            setIsUpdated(false); // Сбрасываем флаг при ошибке
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
                    <TextUI variant="h2">Профиль</TextUI>

                    <CustomInput
                        name="name"
                        type={InputTypeEnum.FIO}
                        placeholder="ФИО"
                        validate={(value: string) => {
                            if (value.length < 5) {
                                return 'ФИО должно быть не менее 5 символов.';
                            }
                            if (value.length > 32) {
                                return 'ФИО должно быть не более 32 символов.';
                            }
                            return true; // Все проверки пройдены
                        }}
                    />
                    <CustomInput
                        name="messenger"
                        type={InputTypeEnum.Messenger}
                        placeholder="Telegram/Skype/WhatsApp"
                        validate={(value: string) => {
                            if (value.length < 5) {
                                return 'Значение должно быть не менее 5 символов.';
                            }
                            if (value.length > 32) {
                                return 'Значение должно быть не более 32 символов.';
                            }
                            return true; // Все проверки пройдены
                        }}
                    />
                    <CustomInput
                        name="phone"
                        type={InputTypeEnum.Tel}
                        placeholder="Номер телефона"
                    />
                    <CustomInput
                        name="email"
                        type={InputTypeEnum.Email}
                        placeholder="Email"
                    />
                    <div className={styles.buttonWidth}>
                        <ButtonUI type="submit" label="Сохранить" variant="main" disabled={isLoading} height='42px' />
                        {isUpdated && !isMobile && !error && (
                            <div className={styles.settingsSaveBox}>
                                <img src={PencilSettings} className="PencilSettings" alt="Icon" />
                                <p>Ваши изменения были сохранены</p>
                            </div>
                        )}
                    </div>
                    {/* {error && <p className={styles.error}>Ошибка!</p>} */}
                </form>
            </FormProvider>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                message={modalMessage}
                icon={isUpdated && <PencilIcon />}
            />
        </>
    );
};

export default ProfileForm;
