import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { PhotoForm } from 'components/PhotoForm/PhotoForm';
import {
    useGetCardRequestByIdQuery,
    useUpdateRequestStepMutation,
    useRequestSmsCodeMutation
} from 'services/api';

interface Step4Props {
    setIsFormValid: (isValid: boolean) => void;
    onFormSubmit: (data: any) => void;
    requestId: string | null;
    setRequestId: (requestId: any) => void;
}

const Step4: React.FC<Step4Props> = ({ setIsFormValid, onFormSubmit, requestId, setRequestId }) => {
    const { data: cardRequestData, refetch } = useGetCardRequestByIdQuery(requestId);
    const [updateRequestStep] = useUpdateRequestStepMutation();
    const [requestSmsCode] = useRequestSmsCodeMutation();

    const methods = useForm({
        mode: 'onChange',
        defaultValues: cardRequestData || {},
    });

    const { reset } = methods;

    useEffect(() => {
        if (cardRequestData) {
            reset(cardRequestData);
        }
    }, [cardRequestData, reset]);

    useEffect(() => {
        refetch();
    }, [refetch]);

    const handleFormValidityChange = (isValid: boolean) => {
        setIsFormValid(isValid);
    };

    const onSubmit = async (data: any) => {
        console.log(" ~ Step4 ~ onSubmit ~ data:", data);

        if (!requestId) {
            console.error('No requestId available');
            return;
        }

        try {
            // Step 1: Update the request with Step 4 data
            console.log(" ~ Step4 ~ Updating request step");
            await updateRequestStep({ id: requestId, step: 4, data }).unwrap();

            // Step 2: Request SMS code
            console.log(" ~ Step4 ~ Requesting SMS code");
            const smsResponse = await requestSmsCode(requestId).unwrap();
            console.log(" ~ Step4 ~ SMS response:", smsResponse);

            if (smsResponse?.message === 'SMS код успешно отправлен') {
                // Убираем автоматический переход на следующий шаг
                console.log(" ~ Step4 ~ SMS код успешно отправлен");
                // onFormSubmit(data);
            } else {
                console.error('Unexpected SMS response:', smsResponse);
            }
        } catch (error) {
            console.error('Error in Step4 submission:', error);
        }
    };

    return (
        <PhotoForm
            setIsFormValid={handleFormValidityChange}
            onFormSubmit={onSubmit}
            serverData={{
                documentFiles: cardRequestData?.documentPhotos || [],
                clientFiles: cardRequestData?.clientPhoto ? [cardRequestData.clientPhoto] : [],
            }}
            requestId={requestId}
            refetch={refetch}
        />
    );
};

export default Step4;
