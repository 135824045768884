import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { useVerifySmsCodeMutation, useRequestSmsCodeMutation } from 'services/api';
import GreenCheckIcon from 'assets/icons/green-check.svg';

import styles from './SMSCodeForm.module.scss';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

interface SMSCodeFormProps {
    onSubmit: (code: string, success: boolean) => void;
    requestId: string | null;
    isVerified?: boolean;
}

export const SMSCodeForm: React.FC<SMSCodeFormProps> = ({ onSubmit, requestId, isVerified = false }) => {
    console.log("🚀 ~ isVerified:", isVerified)
    const { control, handleSubmit } = useForm();
    const [code, setCode] = useState(['', '', '', '', '', '']);
    const [verifyError, setVerifyError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [resendDisabled, setResendDisabled] = useState(false);
    const [verifySmsCode] = useVerifySmsCodeMutation();
    // const [requestSmsCode] = useRequestSmsCodeMutation();

    const handleInputChange = (value: string, index: number) => {
        if (value.length > 1) {
            value = value[0];
        }

        if (!/^\d*$/.test(value)) {
            return;
        }

        const newCode = [...code];
        newCode[index] = value;
        setCode(newCode);

        if (value && index < 5) {
            const nextInput = document.getElementById(`code-${index + 1}`);
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const handleResendCode = async () => {
        if (!requestId) return;

        try {
            setResendDisabled(true);
            setVerifyError(null);

            // const result = await requestSmsCode(requestId).unwrap();
            // console.log("🚀 ~ handleResendCode ~ result:", result);

            // if (result.smsResult?.includes('ERROR')) {
            //     setVerifyError(result.smsResult);
            // } else {
            //     // Очищаем поля ввода
            //     setCode(['', '', '', '', '', '']);
            //     // Фокусируемся на первом поле
            //     const firstInput = document.getElementById('code-0');
            //     if (firstInput) {
            //         firstInput.focus();
            //     }
            // }

            // Включаем кнопку повторной отправки через 60 секунд
            setTimeout(() => {
                setResendDisabled(false);
            }, 60000);

        } catch (error) {
            console.error('Ошибка при отправке SMS-кода:', error);
            setVerifyError('Ошибка при отправке SMS-кода');
        }
    };

    const onFormSubmit = async () => {
        if (!requestId) return;

        const fullCode = code.join('');
        if (fullCode.length === 6) {
            setIsSubmitting(true);
            setVerifyError(null);

            try {
                const response = await verifySmsCode({
                    requestId,
                    code: fullCode
                }).unwrap();

                if (response.success) {
                    onSubmit(fullCode, true);
                } else {
                    setVerifyError(response.message || 'Неверный код');
                    onSubmit(fullCode, false);
                }
            } catch (error) {
                setVerifyError('Неверный код');
                onSubmit(fullCode, false);
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    return (
        <div>
            <form className={styles.smsCodeForm} onSubmit={(e) => { e.preventDefault(); onFormSubmit(); }}>
                <TextUI variant="bodyL" align="left" color='secondary'>
                    Клиенту отправили анкету и согласие на ОПД с кодом СМС.
                </TextUI>
                <div className={styles.codeInputContainer}>
                    <label>Введите код из СМС</label>
                    {isVerified ? <div className={styles.successMessage}>
                        <img src={GreenCheckIcon} alt="Success" className={styles.successIcon} />
                        <span>Код подтвержден</span>
                    </div> :
                        <div>
                            <div className={styles.codeInputWithError}>
                                <div className={styles.codeInputWrapper}>
                                    {code.map((digit, index) => (
                                        <Controller
                                            key={index}
                                            name={`code-${index}`}
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <input
                                                    {...field}
                                                    id={`code-${index}`}
                                                    type="text"
                                                    inputMode="numeric"
                                                    pattern="[0-9]*"
                                                    maxLength={1}
                                                    value={code[index]}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleInputChange(e.target.value, index);
                                                    }}
                                                    className={`${styles.codeInput} ${verifyError ? styles.error : ''}`}
                                                    autoComplete="off"
                                                    disabled={isSubmitting || isVerified}
                                                />
                                            )}
                                        />
                                    ))}
                                </div>
                                {verifyError && (
                                    <div className={styles.errorMessage}>
                                        {verifyError}
                                    </div>
                                )}
                            </div>
                            <div className={styles.buttonsActionContainer}>
                                <ButtonUI
                                    type="submit"
                                    width='145px'
                                    label={isSubmitting ? 'Проверка...' : 'ПОДТВЕРДИТЬ'}
                                    variant='main'
                                    onClick={handleResendCode}
                                    disabled={code.join('').length !== 6 || isVerified || isSubmitting || !!verifyError}
                                />
                                {verifyError && (
                                    <button
                                        type="button"
                                        onClick={handleResendCode}
                                        disabled={resendDisabled}
                                        className={styles.resendButton}
                                    >
                                        ОТПРАВИТЬ КОД ПОВТОРНО
                                    </button>
                                )}
                            </div>
                        </div>
                    }
                </div>
            </form>
        </div>
    );
};
