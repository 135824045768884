import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { UserData } from 'shared/models';
import axios from 'axios';

interface ConfirmationCodeData {
    confirmationCode: string;
}

const baseUrl = process.env.REACT_APP_API_URL

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: fetchBaseQuery({ baseUrl: baseUrl + '/api' }),
    endpoints: (builder) => ({
        fetchOffers: builder.query({
            query: () => '/user/offers',
        }),
        registerUser: builder.mutation({
            query: (userData: UserData) => ({
                url: '/user/registration',
                method: 'POST',
                body: userData,
            }),
        }),
        loginUser: builder.mutation({
            query: (userData: any) => ({
                url: '/user/login',
                method: 'POST',
                body: userData,
            }),
        }),
        approveRegistrationCode: builder.mutation({
            query: (data: ConfirmationCodeData) => ({
                url: '/user/verify-code',
                method: 'POST',
                body: data,
            }),
        }),
        fetchPartner: builder.query({
            query: (pid: string) => `/user/partners?affiseId=${pid}`,
        }),
        repeatConfirmCode: builder.mutation({
            query: (email: string) => ({
                url: '/user/repeat-confirm-code',
                method: 'POST',
                body: { email },
            }),
        }),
        checkAuth: builder.query({
            query: () => '/user/refresh',
        }),
        updateUser: builder.mutation({
            query: (userData: Partial<UserData>) => ({
                url: `/user/update-user`, // Это ваш эндпоинт для обновления пользователя
                method: 'PUT',
                body: userData,
            }),
        }),
        updatePassword: builder.mutation({
            query: (data: { email: string; currentPassword?: string; newPassword: string; recoverPasswordLink?: string, timestamp?: string }) => ({
                url: `/user/updatePassword`,
                method: 'POST',
                body: data,
            }),
        }),
        fetchDailyStatistic: builder.query({
            query: () => '/user/daily-statistic',
        }),
        getOfferStatistics: builder.query({
            query: () => '/user/offer-statistic',
        }),
        uploadFiles: builder.mutation<{ documentPhotosUrl: string; clientPhotoUrl: string }, { formData: FormData; id: string }>({
            query: ({ formData, id }) => ({
                url: `/bids/${id}/upload`,  // Обновляем URL для нового эндпоинта
                method: 'POST',
                body: formData,
            }),
        }),
        updateBidSandbox: builder.mutation<{ success: boolean; sandbox: boolean }, { id: string; sandbox: boolean }>({
            query: ({ id, sandbox }) => ({
                url: `/bids/${id}/sandbox`,
                method: 'PATCH',
                body: { sandbox },
            }),
        }),
        getApplications: builder.query({
            query: () => '/bids',
            transformResponse: (response: any[]) => {
                return response.map(bid => ({
                    id: bid._id,
                    date: bid.createdAt,
                    offer: bid.offer,
                    status: bid.status,
                    paymentStatus: bid.paymentStatus || 'pending',
                    client: bid.personalData?.name || '',
                    bidNumber: bid.bidNumber
                }));
            }
        }),
        // Новый эндпоинт для создания новой заявки
        createCardRequest: builder.mutation({
            query: (data) => ({
                url: `/bids/create`,
                method: 'POST',
                body: data,
            }),
        }),
        // Новый эндпоинт для создания или обновления данных заявки по шагам
        updateRequestStep: builder.mutation({
            query: ({ id, step, data }) => ({
                url: `/bids/${id}/step/${step}`,
                method: 'PUT',
                body: data,
            }),
        }),
        requestSmsCode: builder.mutation({
            query: (requestId: string) => ({
                url: `/bids/${requestId}/send-phone-code`,
                method: 'GET',
            }),
        }),
        verifySmsCode: builder.mutation({
            query: ({ requestId, code }) => ({
                url: `/bids/${requestId}/verify-phone-code`,
                method: 'POST',
                body: { code },
            }),
        }),
        getCardRequestById: builder.query({
            query: (id) => `/bids/${id}`,
        }),
        // Новый эндпоинт для обновления статуса заявки
        updateRequestStatus: builder.mutation({
            query: ({ id, status }) => ({
                url: `/bids/${id}/status`,
                method: 'PATCH',
                body: { status },
            }),
        }),
        // getPhotoData: builder.query({
        //     query: (id: string) => `/bids/${id}/photos`,
        // }),
        // Удаление фото документов
        deleteDocumentPhoto: builder.mutation({
            query: ({ id, photoPath }) => ({
                url: `/bids/${id}/document`,
                method: 'DELETE',
                body: { photoPath }, // передаем путь к фото в теле запроса
            }),
        }),
        // Удаление фото клиента
        deleteClientPhoto: builder.mutation({
            query: ({ id, photoPath }) => ({
                url: `/bids/${id}/client`,
                method: 'DELETE',
                body: { photoPath }, // передаем путь к фото в теле запроса
            }),
        }),
        getBidsByUserId: builder.query({
            query: (userId) => `/bids/user/${userId}`,
        }),
        getAllBids: builder.query({
            query: () => '/bids',
        }),
        // Инициализация кредитной карты
        initCreditCard: builder.mutation({
            query: ({ requestId }) => ({
                url: `/partners/vtb/credit-card/process/${requestId}/init`,
                method: 'POST',
                body: {}
            })
        }),
        checkCreditCard: builder.mutation<any, { id: string | null; ean: string }>({
            query: ({ id, ean }) => ({
                url: `/partners/vtb/credit-card/process/${id}/check-card`,
                method: 'POST',
                body: { ean }
            })
        }),
        getBidStatus: builder.query({
            query: ({ id, type }) => `/bids/${id}/status/${type}`
        }),
        // getCardCheckStatus: builder.query({
        //     query: (id) => `/partners/vtb/callback/credit-card/process/${id}/check-card`
        // }),
        getCardStatus: builder.query<any, string>({
            query: (id) => `/partners/vtb/callback/credit-card/process/${id}/check-card`,
            transformResponse: (response: any) => {
                return response;
            }
        }),
        // Отмена кредитной карты
        cancelCreditCard: builder.mutation<any, { requestId: string }>({
            query: ({ requestId }) => ({
                url: `/partners/vtb/credit-card/process/${requestId}/cancel`,
                method: 'POST',
            }),
        }),
        getPublicUrl: builder.query({
            query: (id: string) => `/bid/${id}/public-url`,
        }),
        getByHash: builder.query({
            query: (hash: string) => `/bid/pub/${hash}`,
        }),
    }),
});

export const {
    useFetchOffersQuery,
    useRegisterUserMutation,
    useLoginUserMutation,
    useApproveRegistrationCodeMutation,
    useFetchPartnerQuery,
    useRepeatConfirmCodeMutation,
    useCheckAuthQuery,
    useVerifySmsCodeMutation,
    useUpdateUserMutation,
    useUpdatePasswordMutation,
    useFetchDailyStatisticQuery,
    useGetOfferStatisticsQuery,
    useUploadFilesMutation,
    useGetApplicationsQuery,
    useCreateCardRequestMutation,
    useGetCardRequestByIdQuery,
    useUpdateRequestStepMutation,
    useUpdateRequestStatusMutation,
    useRequestSmsCodeMutation,
    useDeleteDocumentPhotoMutation,
    useDeleteClientPhotoMutation,
    useGetBidsByUserIdQuery,
    useUpdateBidSandboxMutation,
    useGetAllBidsQuery,
    useInitCreditCardMutation,
    useCheckCreditCardMutation,
    useGetCardStatusQuery,
    useGetBidStatusQuery,
    useCancelCreditCardMutation,
    useGetPublicUrlQuery,
    useGetByHashQuery,
} = apiSlice;