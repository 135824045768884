import React from 'react'
import MainAppLayout from 'app/layouts/MainAppLayout/MainAppLayout'
import { OffersMainList } from 'components/OffersMainList'
import { PendingApplicationsList } from 'components/PendingApplicationsList'

export default function MainPage() {
    return (
        <MainAppLayout>
            <OffersMainList />
            <PendingApplicationsList />
        </MainAppLayout>
    )
}