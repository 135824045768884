import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useInitCreditCardMutation, useGetBidStatusQuery, useCancelCreditCardMutation } from '../../services/api';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import LoadingIcon from 'assets/icons/loading-icon.svg';
import GreenCheckIcon from 'assets/icons/green-check.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';
import { PulseLoader } from 'react-spinners';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';

import styles from './Step6.module.scss';

interface Step6Props {
    requestId: string | null;
    setIsFormValid?: (isValid: boolean) => void;
}

const Step6: React.FC<Step6Props> = ({ requestId, setIsFormValid }) => {
    const [status, setStatus] = useState<'idle' | 'loading' | 'success' | 'error'>('idle');
    const [message, setMessage] = useState('');
    const [hint, setHint] = useState('');
    const [error, setError] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [isPolling, setIsPolling] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const requestedAmount = useSelector((state: any) => state.request.data.requestedAmount);
    const [initCreditCard, { isLoading: isInitLoading }] = useInitCreditCardMutation();
    const [cancelCreditCard] = useCancelCreditCardMutation();
    const { data: statusData, refetch } = useGetBidStatusQuery(
        requestId ? { id: requestId, type: 'init' } : skipToken,
        {
            pollingInterval: isPolling ? 3000 : 0,
            refetchOnMountOrArgChange: true
        }
    );
    console.log("🚀 ~ statusData:", statusData)

    // Эффект для обработки статуса
    useEffect(() => {
        if (!statusData) return;

        const code = statusData.message?.partnerResponseCode;
        if (code !== undefined) {
            setMessage(statusData.message.partnerResponseMessage || '');
            setHint(statusData.message.hint || '');

            if (code !== -1) {
                const isSuccess = code === 0;
                setStatus(isSuccess ? 'success' : 'error');
                if (setIsFormValid) {
                    setIsFormValid(isSuccess);
                }
                setIsPolling(false);
                setIsLoading(false);
            }
        }
    }, [statusData, setIsFormValid]);

    // Сброс валидности формы при размонтировании
    useEffect(() => {
        return () => {
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        };
    }, [setIsFormValid]);

    const handleCreditCardInit = async () => {
        try {
            setIsLoading(true);
            setError('');
            setStatus('loading');
            setMessage('Отправляем запрос в банк...');
            setHasSubmitted(true);
            if (setIsFormValid) {
                setIsFormValid(false);
            }

            const initResult = await initCreditCard({ requestId }).unwrap();
            console.log("🚀 ~ initResult:", initResult);

            setIsPolling(true);
            setMessage('Получаем ответ от банка...');

            const pollStatus = async () => {
                if (!isPolling) return;

                try {
                    const statusResult = await refetch().unwrap();
                    console.log("🚀 ~ statusResult:", statusResult);

                    if (!statusResult.message?.partnerResponseCode) {
                        setTimeout(pollStatus, 2000);
                    }
                } catch (error) {
                    console.error('Polling error:', error);
                    setIsPolling(false);
                    setStatus('error');
                    setError('Ошибка при получении статуса');
                    setIsLoading(false);
                    if (setIsFormValid) {
                        setIsFormValid(false);
                    }
                }
            };

            pollStatus();
        } catch (error) {
            console.error('Error:', error);
            setStatus('error');
            setError('Произошла ошибка при инициализации карты');
            setIsPolling(false);
            setIsLoading(false);
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        }
    };

    const handleReset = () => {
        setStatus('idle');
        setMessage('');
        setHint('');
        setError('');
        setIsPolling(false);
        setIsLoading(false);
        if (setIsFormValid) {
            setIsFormValid(false);
        }
    };

    const handleCancel = async () => {
        try {
            if (!requestId) return;

            setIsLoading(true);
            setMessage('Отменяем заявку...');

            await cancelCreditCard({ requestId }).unwrap();

            setStatus('idle');
            setMessage('');
            setHint('');
            setError('');
            setIsPolling(false);
            setHasSubmitted(false);
            setIsLoading(false);
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        } catch (error) {
            console.error('Cancel error:', error);
            setError('Ошибка при отмене заявки');
            setIsLoading(false);
        }
    };

    return (
        <CardWrapper minWidth="512">
            <div className={styles.content}>
                <div className={styles.amount}>Кредитный лимит: {requestedAmount} ₽</div>
                <div className={styles.subLabel}>Решение по выдаче карты:</div>

                {status === 'idle' && !isLoading && (
                    <ButtonUI
                        label={'Запрос на кредит'}
                        variant={'secondary'}
                        onClick={handleCreditCardInit}
                        disabled={isLoading}
                    />
                )}

                {isLoading && (
                    <div className={styles.loadingContainer}>
                        <PulseLoader
                            color="#0066FF"
                            size={15}
                            margin={2}
                            speedMultiplier={0.8}
                        />
                        <div className={styles.loadingText}>{message}</div>
                    </div>
                )}

                {!isLoading && status === 'success' && (
                    <div className={styles.resultContainer}>
                        <img src={GreenCheckIcon} alt="Успех" className={styles.statusIcon} />
                        <div className={styles.messageContainer}>
                            <div className={styles.message}>{message}</div>
                            {hint && <div className={styles.hint}>{hint}</div>}
                        </div>
                    </div>
                )}

                {!isLoading && status === 'error' && (
                    <>
                        <div className={styles.resultContainer}>
                            <img src={ErrorIcon} alt="Ошибка" className={styles.statusIcon} />
                            <div className={styles.messageContainer}>
                                <div className={styles.message}>{message || error}</div>
                                {hint && <div className={styles.hint}>{hint}</div>}
                            </div>
                        </div>
                        {statusData?.message?.partnerResponseCode === 1 ? (
                            <ButtonUI
                                label={'Отменить заявку'}
                                variant={'secondary'}
                                onClick={handleCancel}
                                className={styles.retryButton}
                            />
                        ) : (
                            <ButtonUI
                                label={'Попробовать еще раз'}
                                variant={'secondary'}
                                onClick={handleReset}
                                className={styles.retryButton}
                            />
                        )}
                    </>
                )}
            </div>
        </CardWrapper>
    );
};

export default Step6;
