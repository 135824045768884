import React from 'react';
import styles from './Counter.module.scss';

interface CounterProps {
    initialValue: number;
    min?: number;
    max?: number;
    onChange: (value: number) => void;
}

export const Counter: React.FC<CounterProps> = ({ initialValue, min = 0, max = 10, onChange }) => {
    const handleIncrement = () => {
        if (initialValue < max) {
            onChange(initialValue + 1);
        }
    };

    const handleDecrement = () => {
        if (initialValue > min) {
            onChange(initialValue - 1);
        }
    };

    return (
        <div className={styles.counterContainer}>
            <button 
                className={styles.decrementButton} 
                onClick={handleDecrement} 
                disabled={initialValue === min}
                type="button"
            >
                -
            </button>
            <span className={styles.counterValue}>{initialValue}</span>
            <button 
                className={styles.incrementButton} 
                onClick={handleIncrement} 
                disabled={initialValue === max}
                type="button"
            >
                +
            </button>
        </div>
    );
};

export default Counter;
