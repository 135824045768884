import React from 'react';
import { useParams } from 'react-router-dom';
import styles from './PublicBidPage.module.scss';
import { useGetByHashQuery } from 'services/api';
// import { Loader } from 'shared/ui';

export const PublicBidPage: React.FC = () => {
    const { hash } = useParams<{ hash: string }>();
    const { data: bid, isLoading, error } = useGetByHashQuery(hash || '');

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return (
            <div className={styles.errorContainer}>
                <h2>Ошибка</h2>
                <p>Заявка не найдена или срок действия ссылки истек</p>
            </div>
        );
    }

    return (
        <div className={styles.container}>
            <h1>Подтверждение заявки</h1>
            {bid && (
                <div className={styles.content}>
                    <div className={styles.bidInfo}>
                        <h2>Информация о заявке</h2>
                        <p>
                            <strong>ФИО:</strong> {bid.personalData?.lastName} {bid.personalData?.firstName} {bid.personalData?.middleName}
                        </p>
                        <p>
                            <strong>Телефон:</strong> {bid.personalData?.phone}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};
