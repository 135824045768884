import React from 'react';
import { SliderSwitch } from 'components/SliderSwitch';
import Select from 'react-select';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';
import { useGetCardRequestByIdQuery } from '../../services/api';

import styles from './ProgressSteps.module.scss';

interface ProgressStepsProps {
  currentStep: number;
  totalSteps: number;
  onStepClick: (step: number) => void;
  requestId: string | null;
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    height: '42px',
    borderRadius: '21px', // Скругление краев
    border: '1px solid #ccc', // Цвет границы
    boxShadow: 'none', // Убираем стандартный тень
    '&:hover': {
      borderColor: '#aaa', // Цвет границы при наведении
    },
  }),
  indicatorSeparator: () => ({
    display: 'none', // Убираем вертикальную разделительную черту
  }),
};

const ProgressSteps: React.FC<ProgressStepsProps> = ({
  currentStep,
  totalSteps,
  onStepClick,
  requestId
}) => {
  const isMobile = useMediaQuery(768); // Используем хук для проверки ширины экрана
  const { data: bid } = useGetCardRequestByIdQuery(requestId, {
    skip: !requestId
  });

  // Создаем массив опций для Select
  const stepsOptions = Array.from({ length: totalSteps }, (_, i) => ({
    value: i + 1,
    label: `Шаг ${i + 1}`,
  }));

  const approvalProbability = 55 + (currentStep - 1) * 10;

  return (
    <>
      <div className={styles.progressSteps}>
        {requestId && bid && (
          <SliderSwitch
            bidId={requestId} // ID текущей заявки
            initialSandbox={bid.sandbox} // Начальное значение из заявки
            onChange={(newValue) => console.log('Sandbox status changed:', newValue)}
          />
        )}
        {currentStep !== 0 && (isMobile ? (
          <Select
            options={stepsOptions}
            value={stepsOptions.find((option) => option.value === currentStep)}
            onChange={(selectedOption) => {
              if (selectedOption) {
                onStepClick(selectedOption.value);
              }
            }}
            className={styles.mobileSelect}
            classNamePrefix="select"
            placeholder={`Шаг ${currentStep}`}
            styles={customStyles}
          />
        ) : (
          stepsOptions.map((stepOption) => (
            <div
              key={stepOption.value}
              className={`${styles.step} ${stepOption.value === currentStep ? styles.activeStep : ''
                }`}
              onClick={() => onStepClick(stepOption.value)}
            >
              <span>{stepOption.label}</span>
            </div>
          ))
        ))}
      </div>

      {/* Индикатор вероятности одобрения */}
      {currentStep !== 0 && currentStep < 5 &&
        <div className={styles.approvalIndicator}>
          <span>Вероятность одобрения:</span>
          <span className={styles.probabilityText}>{`+${approvalProbability}%`}</span>
          <div className={styles.progressBar}>
            <div
              className={styles.progressFill}
              style={{ width: `${approvalProbability}%` }}
            />
          </div>
        </div>
      }
    </>
  );
};

export default ProgressSteps;
