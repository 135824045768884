import React, { useState, useEffect } from 'react';
import { useCheckCreditCardMutation, useGetBidStatusQuery } from '../../services/api';
import EanCardCheckForm from '../EanCardCheckForm/ui/EanCardCheckForm';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';

import styles from './Step7.module.scss';

const POLLING_INTERVAL = 3000; // 3 секунды
const MAX_POLLING_ATTEMPTS = 20; // Максимальное количество попыток (1 минута)

interface Step7Props {
    requestId: string | null;
    setIsFormValid?: (isValid: boolean) => void;
}

const Step7: React.FC<Step7Props> = ({ requestId, setIsFormValid }) => {
    const [checkCreditCard] = useCheckCreditCardMutation();
    const [isPolling, setIsPolling] = useState(false);
    const [pollingCount, setPollingCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [statusMessage, setStatusMessage] = useState<string | null>(null);
    const [isSuccess, setIsSuccess] = useState(false);
    const isMobile = useMediaQuery(768);

    // Используем RTK Query для пуллинга
    const { data: statusData, error: statusError } = useGetBidStatusQuery(
        { id: requestId!, type: 'check_card' },
        {
            skip: !isPolling || !requestId,
            pollingInterval: POLLING_INTERVAL,
            refetchOnMountOrArgChange: true
        }
    );
    console.log("🚀 ~ statusData:", statusData)

    // Эффект для обработки ответа от пуллинга
    useEffect(() => {
        if (statusData) {
            console.log('Status data:', statusData);

            // Обработка ответа с кодом партнера
            if (statusData.message?.partnerResponseCode !== undefined) {
                const { partnerResponseCode, partnerResponseMessage, hint } = statusData.message;

                if (partnerResponseCode !== -1) {
                    const isSuccessStatus = partnerResponseCode === 0 || partnerResponseCode === 6;
                    setIsSuccess(isSuccessStatus);
                    if (setIsFormValid) {
                        setIsFormValid(isSuccessStatus);
                    }
                    setStatusMessage(partnerResponseMessage);
                    if (hint) {
                        setErrorMessage(hint);
                    }
                    setIsPolling(false);
                    setIsLoading(false);
                    return;
                }
            }

            // Обработка сообщения от банка
            if (statusData.message === "Банк еще не ответил.") {
                setStatusMessage("Ожидание ответа от банка...");
                return;
            }
        }
    }, [statusData, setIsFormValid]);

    // Сброс валидности формы при размонтировании
    useEffect(() => {
        return () => {
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        };
    }, [setIsFormValid]);

    // Эффект для остановки пуллинга по таймауту
    useEffect(() => {
        if (isPolling && pollingCount >= MAX_POLLING_ATTEMPTS) {
            setIsPolling(false);
            setIsLoading(false);
            setErrorMessage('Превышено время ожидания ответа');
        }
    }, [isPolling, pollingCount]);

    const handleFormSubmit = async (data: { ean: string }) => {
        try {
            setIsLoading(true);
            setHasSubmitted(true);
            setErrorMessage(null);
            setStatusMessage(null);
            setPollingCount(0);
            if (setIsFormValid) {
                setIsFormValid(false);
            }

            // Отправляем запрос на проверку карты
            const response = await checkCreditCard({ id: requestId, ean: data.ean }).unwrap();
            console.log('Check card response:', response);

            if (response.error) {
                setErrorMessage(response.error);
                setIsSuccess(false);
                setIsLoading(false);
                if (setIsFormValid) {
                    setIsFormValid(false);
                }
            } else {
                // Запускаем пуллинг статуса
                setIsPolling(true);
            }
        } catch (error: any) {
            console.error('Ошибка при проверке карты:', error);
            setErrorMessage(error.message || 'Произошла ошибка при проверке карты');
            setIsSuccess(false);
            setIsLoading(false);
            setHasSubmitted(false);
            if (setIsFormValid) {
                setIsFormValid(false);
            }
        }
    };

    const handleReset = () => {
        setHasSubmitted(false);
        setErrorMessage(null);
        setStatusMessage(null);
        setIsSuccess(false);
        setPollingCount(0);
        if (setIsFormValid) {
            setIsFormValid(false);
        }
    };

    return (
        <div className={styles.container}>
            <EanCardCheckForm
                onSubmit={handleFormSubmit}
                onReset={handleReset}
                isLoading={isLoading}
                isPolling={isPolling}
                hasSubmitted={hasSubmitted}
                errorMessage={errorMessage}
                statusMessage={statusMessage}
                isSuccess={isSuccess}
                isMobile={isMobile}
            />
        </div>
    );
};

export default Step7;