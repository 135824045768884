import React from 'react';

interface LockedPadlockProps {
  scale?: number; // Новый проп для управления масштабом
}

const LockedPadlock: React.FC<LockedPadlockProps> = ({ scale = 1 }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        transform: `scale(${scale})`,
        transformOrigin: 'center',
      }}
    >
      <g clipPath="url(#clip0_1510_4565)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.44488 8.14823C3.1856 8.14823 2.22266 9.11118 2.22266 10.3705V14.0742C2.22266 17.3334 4.88932 20.0001 8.14858 20.0001H11.8523C15.1115 20.0001 17.7782 17.3334 17.7782 14.0742V10.3704C17.7782 9.18943 16.8221 8.15555 15.5694 8.14823H4.44488ZM10.3721 14V15.9259C10.3721 16.1481 10.2239 16.2963 10.0017 16.2963C9.77951 16.2963 9.63137 16.1481 9.63137 15.9259V14C9.18692 13.8518 8.89062 13.4073 8.89062 12.9629C8.89062 12.3703 9.40916 11.8518 10.0018 11.8518C10.5943 11.8518 11.1129 12.3703 11.1129 12.9629C11.1128 13.4814 10.8165 13.8518 10.3721 14Z" fill="#AAAABB" />
        <path d="M15.5568 5.55554L15.5564 7.60143H13.3342L13.3345 5.55554C13.3345 3.7037 11.853 2.22222 10.0012 2.22222C8.14936 2.22222 6.66788 3.7037 6.66788 5.55554L6.66753 7.60143L4.44531 7.60147L4.44566 5.55557C4.44566 2.51852 6.96417 0 10.0012 0C13.0382 0 15.5568 2.51852 15.5568 5.55554Z" fill="#71BE81" />
      </g>
      <defs>
        <clipPath id="clip0_1510_4565">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>

  );
};

export default LockedPadlock;
