import React from 'react';
import cn from 'classnames';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import styles from './CardWrapper.module.scss';

interface CardWrapperProps {
    children: React.ReactNode;
    title?: string;
    noBorder?: boolean;
    className?: string;
    minWidth?: '512';
}

export const CardWrapper: React.FC<CardWrapperProps> = ({
    children,
    title,
    noBorder = false,
    className,
    minWidth
}) => {
    return (
        <div
            className={cn(styles.card, className, {
                [styles.noBorder]: noBorder,
                [styles.minWidth512]: minWidth === '512'
            })}
        >
            {title && <TextUI variant="h2">{title}</TextUI>}
            {children}
        </div>
    );
};
