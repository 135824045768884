import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RequestCardLayout from 'app/layouts/RequestCardLayout/RequestCardLayout';
import { BottonSteps } from 'components/BottonSteps';
// import { useForm, FormProvider, UseFormReturn } from 'react-hook-form';
import ProgressSteps from 'components/ProgressSteps/ProgressSteps';
import {
    setCurrentStep,
    setSelectedBank,
    setSelectedProduct,
    savePersonalData,
    saveAdditionalData,
    setStatus,
    setPartnerResponseMessage,
    setPartnerResponseCode
} from 'features/request/requestSlice';
import { useDispatch, useSelector } from 'react-redux';

import Step0 from 'components/RequestCard/Step0';
import Step1 from 'components/RequestCard/Step1';
import Step2 from 'components/RequestCard/Step2';
import Step3 from 'components/RequestCard/Step3';
import Step4 from 'components/RequestCard/Step4';
import Step5 from 'components/RequestCard/Step5';
import Step6 from 'components/RequestCard/Step6';
import Step7 from 'components/RequestCard/Step7';
import Step8 from 'components/RequestCard/Step8';
import Step9 from 'components/RequestCard/Step9';
import Step10 from 'components/RequestCard/Step10';

import styles from './RequestCardPage.module.scss';
import { useCreateCardRequestMutation, useUpdateRequestStepMutation, useGetCardRequestByIdQuery, useUpdateRequestStatusMutation, useRequestSmsCodeMutation, useInitCreditCardMutation } from 'services/api';

const RequestCardPage: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const { data: { selectedBank = '', selectedProduct = '', personalData = {}, additionalData = {} } = {}, currentStep } = useSelector((state: any) => state.request);
    const requestedAmount = useSelector((state: any) => state.request.data.requestedAmount);
    const userId = useSelector((state: any) => state.login?.user?._id); // TODO: или использовать userId из сквозной нумерации (1, 2, 3, ...) ???

    const [step, setStep] = useState(0);
    const [requestId, setRequestId] = useState<string | null>(null);
    const [responseMessage, setResponseMessage] = useState<string>('');
    const [isStep1Valid, setIsStep1Valid] = useState(false);
    const [isStep3Valid, setIsStep3Valid] = useState(false);
    const [isStep4Valid, setIsStep4Valid] = useState(false); // Для шага 4
    const [isStep5Valid, setIsStep5Valid] = useState(false); // Для шага 5
    const [isStep6Valid, setIsStep6Valid] = useState(false); // Для шага 6
    const [isStep7Valid, setIsStep7Valid] = useState(false); // Для шага 7
    const [smsError, setSmsError] = useState<string | null>(null);
    // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    // const [photoFormSubmit, setPhotoFormSubmit] = useState<(() => void) | null>(null);

    const TOTAL_STEPS = 10; // Устанавливаем общее количество шагов
    const disableNextStepOff = false;

    const [createCardRequest] = useCreateCardRequestMutation();
    const [updateRequestStep] = useUpdateRequestStepMutation();
    // const [updateRequestStatus] = useUpdateRequestStatusMutation();
    const [requestSmsCode] = useRequestSmsCodeMutation();
    const [initCreditCardProcess] = useInitCreditCardMutation();

    // Подтягиваем данные с сервера при наличии requestId и изменении шага
    const { data: requestData, refetch } = useGetCardRequestByIdQuery(requestId, { skip: !requestId });
    // console.log("🚀 ~ requestData:", requestData)

    useEffect(() => {
        if (location?.state?.selectedBank) {
            dispatch(setSelectedBank(location?.state.selectedBank));
        }
        if (location?.state?.selectedProduct) {
            dispatch(setSelectedProduct(location?.state.selectedProduct));
        }
    }, [location?.state, dispatch]);

    // // Загружаем значение из localStorage при первой загрузке компонента
    // useEffect(() => {
    //     const storedCheckboxState = localStorage.getItem('isCheckboxChecked');
    //     if (storedCheckboxState !== null) {
    //         setIsCheckboxChecked(JSON.parse(storedCheckboxState));
    //     }
    // }, []);

    // // Сохраняем значение в localStorage при каждом изменении isCheckboxChecked
    // useEffect(() => {
    //     localStorage.setItem('isCheckboxChecked', JSON.stringify(isCheckboxChecked));
    // }, [isCheckboxChecked]);

    // Когда данные с сервера приходят, обновляем Redux
    useEffect(() => {
        if (requestData) {
            dispatch(setSelectedBank(requestData.selectedBank));
            dispatch(setSelectedProduct(requestData.selectedProduct));
            dispatch(savePersonalData(requestData.personalData));
            dispatch(saveAdditionalData(requestData.additionalData));
        }
    }, [requestData, dispatch]);

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const urlStep = searchParams.get('step');
        if (urlStep) {
            const parsedStep = parseInt(urlStep, 10);
            if (!isNaN(parsedStep) && parsedStep >= 0 && parsedStep <= TOTAL_STEPS) {
                setStep(parsedStep);
            }
        }
    }, [location.search]);

    useEffect(() => {
        dispatch(setCurrentStep(step)); // Сохраняем текущий шаг в Redux
    }, [step, dispatch]);

    useEffect(() => {
        // const savedRequestId = localStorage.getItem('requestId');
        if (location.state?.requestId) {
            setRequestId(location.state?.requestId);
        }
    }, []);

    // const handleResetRequest = () => {
    //     localStorage.removeItem('requestId');
    //     setRequestId(null);
    //     // Дополнительные действия по сбросу состояния заявки
    // };

    const updateUrl = (step: number) => {
        const searchParams = new URLSearchParams(location.search);
        searchParams.set('step', step.toString());
        navigate(`?${searchParams.toString()}`, { replace: true });
    };

    // const handleBankChange = (bank: string) => {
    //     dispatch(setSelectedBank(bank));
    // };

    // const handleProductChange = (product: string) => {
    //     dispatch(setSelectedProduct(product));
    // };

    const sendDocsAndClientPhotos = async (data: any) => {
        if (!requestId) {
            try {
                // Создание новой заявки, если requestId отсутствует
                const response = await createCardRequest({ ...data, userId }).unwrap();
                setRequestId(response._id);
                // Запрашиваем SMS код после создания заявки
                await requestSmsCode(response._id).unwrap();
            } catch (error) {
                console.error('Ошибка при создании заявки:', error);
                return;
            }
        } else {
            try {
                // Обновление существующей заявки на шаге 4
                await updateRequestStep({ id: requestId, step, data }).unwrap();
                // Запрашиваем SMS код после обновления заявки
                await requestSmsCode(requestId).unwrap();
                refetch();
            } catch (error) {
                console.error('Ошибка при обновлении заявки на шаге 4:', error);
                return;
            }
        }
    };

    const handleNextStep = async (data?: any) => {

        if (step === 4) {
            try {
                // Отправляем SMS-код при переходе на следующий шаг
                const result = await requestSmsCode(requestId as string).unwrap();
                console.log(" ~ RequestCardPage ~ SMS response:", result);

                if (result.smsResult?.includes('ERROR')) {
                    setSmsError(result.smsResult);
                } else {
                    setSmsError(null);
                }

                setStep((prevStep) => prevStep + 1);
                updateUrl(step + 1);
            } catch (error) {
                console.error('Ошибка при отправке SMS-кода:', error);
                setSmsError('Ошибка при отправке SMS-кода');
            }
            return;
        }

        if (step === 5 && requestId) {
            // await handleInitCreditCard();
            setStep((prevStep) => prevStep + 1);
            updateUrl(step + 1);
            return;
        }

        if (step === 6 && requestId) {
            setStep((prevStep) => prevStep + 1);
            updateUrl(step + 1);
            return;
        }

        try {
            let stepData;

            switch (step) {
                case 0:
                    stepData = { selectedBank, selectedProduct };
                    break;
                case 1:
                    stepData = personalData;
                    break;
                case 2:
                    stepData = { requestedAmount };
                    break;
                case 3:
                    stepData = additionalData;
                    break;
                default:
                    stepData = {};
                    break;
            }

            if (!requestId) {
                // Создание новой заявки, если requestId отсутствует
                const response = await createCardRequest({ ...stepData, userId }).unwrap();
                setRequestId(response._id);
            } else {
                // Обновление существующей заявки на определённом шаге
                await updateRequestStep({ id: requestId, step, data: stepData }).unwrap();
                refetch();
            }

            // Переход к следующему шагу
            setStep((prevStep) => prevStep + 1);
            updateUrl(step + 1);
        } catch (error) {
            console.error('Ошибка при сохранении шага:', error);
        }
    };

    const handlePrevStep = () => {
        if (step > 0) {
            setStep(step - 1);
            updateUrl(step - 1);
        }
    };

    const handleInitCreditCard = async () => {
        try {
            if (requestId) {
                // First move to Step6
                setStep((prevStep) => prevStep + 1);
                updateUrl(step + 1);

                // Then initiate the credit card process
                const response = await initCreditCardProcess(requestId).unwrap();

                // If we get an immediate response, update the state
                if (response?.payload?.partnerResponseMessage) {
                    dispatch(setPartnerResponseMessage(response.payload.partnerResponseMessage));
                    if (response.payload.partnerResponseCode) {
                        dispatch(setPartnerResponseCode(response.payload.partnerResponseCode));
                    }
                }

                // Update data
                await refetch();
            }
        } catch (error) {
            console.error('Ошибка при инициализации процесса кредитной карты:', error);
            dispatch(setPartnerResponseMessage('Произошла ошибка при обработке заявки'));
        }
    };

    // Синхронизируем responseMessage с Redux store
    useEffect(() => {
        if (responseMessage) {
            dispatch(setPartnerResponseMessage(responseMessage));
        }
    }, [responseMessage, dispatch]);

    return (
        <RequestCardLayout>
            <ProgressSteps
                currentStep={step}
                totalSteps={TOTAL_STEPS}
                onStepClick={(newStep) => {
                    setStep(newStep);
                    updateUrl(newStep);
                }}
                requestId={requestId}
            />

            <div className={styles.requestCard}>
                {step === 0 ? (
                    <Step0
                        selectedBank={selectedBank}
                        selectedProduct={selectedProduct}
                        onBankChange={(bank) => dispatch(setSelectedBank(bank))}
                        onProductChange={(product) => dispatch(setSelectedProduct(product))}
                    />
                ) : step === 1 ? (
                    <Step1
                        requestId={requestId} // Передаем requestId в Step1
                        // setIsCheckboxChecked={setIsCheckboxChecked}
                        setIsFormValid={setIsStep1Valid}
                        onFormSubmit={() => { }}
                        defaultValues={requestData ? requestData.personalData : {}}
                    />
                ) : step === 2 ? (
                    <Step2 requestId={requestId} />
                ) : step === 3 ? (
                    <Step3 requestId={requestId} setIsFormValid={setIsStep3Valid} onFormSubmit={handleNextStep} />
                ) : step === 4 ? (
                    <Step4 requestId={requestId} setRequestId={setRequestId} setIsFormValid={setIsStep4Valid} onFormSubmit={handleNextStep} />
                ) : step === 5 ? (
                    <Step5
                        setIsFormValid={setIsStep5Valid}
                        handleFormSubmit={() => { }}
                        requestId={requestId as string}
                        smsError={smsError}
                    />
                ) : step === 6 ? (
                    <Step6
                        requestId={requestId}
                        setIsFormValid={setIsStep6Valid}
                    />
                ) : step === 7 ? (
                    <Step7
                        requestId={requestId}
                        setIsFormValid={setIsStep7Valid}
                    />
                ) : step === 8 ? (
                    <Step8 />
                ) : step === 9 ? (
                    <Step9 />
                ) : step === 10 ? (
                    <Step10 />
                ) : null}
            </div>

            <BottonSteps
                step={step}
                onNext={handleNextStep}
                onPrev={handlePrevStep}
                disableNext={
                    disableNextStepOff
                        ? false
                        : (step === 0 && (!selectedBank || !selectedProduct)) ||
                        (step === 1 && (!isStep1Valid)) || // || !isCheckboxChecked
                        (step === 3 && !isStep3Valid) ||
                        (step === 4 && !isStep4Valid) ||
                        (step === 5 && !isStep5Valid) ||// временно разблокировано, а позже вернуть !isStep5Valid
                        (step === 6 && !isStep6Valid) ||
                        (step === 7 && !isStep7Valid)
                }
            // disableNext={false}
            />
        </RequestCardLayout>
    );
};

export default RequestCardPage;