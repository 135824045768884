import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { CustomInput } from 'components/CustomInput/CustomInput';
import { InputTypeEnum } from 'shared/models';
import ButtonUI from 'components/ui/ButtonUI/ButtonUI';
import { useUpdatePasswordMutation } from 'services/api'; // Импортируем хук RTK Query
import { useSelector } from 'react-redux';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { Modal } from 'components/ui/Modal';
import PencilIcon from 'assets/icons/PencilIcon';
import { useMediaQuery } from 'shared/hooks/UseMediaQuery';

import styles from './ChangePasswordForm.module.scss';
import LockedPadlock from 'assets/icons/LockedPadlock';

interface PasswordData {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
}

const ChangePasswordForm = () => {
    const methods = useForm<PasswordData>();
    const { handleSubmit, watch, setError } = methods;

    const [updatePassword, { isLoading, error }] = useUpdatePasswordMutation(); // Используем хук RTK Query

    const isMobile = useMediaQuery(768);

    const [passwordError, setPasswordError] = useState<string | null>(null);
    const [isPasswordUpdated, setIsPasswordUpdated] = useState(false); // Состояние для отслеживания успешного обновления

    const [isModalOpen, setIsModalOpen] = useState(false); // Управление модалкой
    const [modalMessage, setModalMessage] = useState(''); // Сообщение для модалки

    const userEmail = useSelector((state: any) => state.login?.user?.email);

    const currentPassword = watch('currentPassword');
    const newPassword = watch('newPassword');
    const confirmPassword = watch('confirmPassword');

    useEffect(() => {
        setIsPasswordUpdated(false);
    }, [currentPassword, newPassword, confirmPassword]);

    const onSubmit = async (data: PasswordData) => {
        if (newPassword !== confirmPassword) {
            setPasswordError('Пароли не совпадают');
            return;
        }

        try {
            const result = await updatePassword({
                email: userEmail,
                currentPassword: data.currentPassword,
                newPassword: data.newPassword,
            }).unwrap();

            // Показываем модалку при успешном сохранении на мобильной версии
            if (isMobile) {
                setModalMessage('Пароль изменен');
                setIsModalOpen(true);
            }

            console.log('Password updated:', result);
            setIsPasswordUpdated(true); // Устанавливаем флаг при успешном обновлении
        } catch (err: any) {

            const errorMessage = 'Некоторые поля не заполнены или заполнены неверно';

            // Открываем модалку с сообщением об ошибке
            setModalMessage(errorMessage);
            setIsModalOpen(true);

            if (err?.data?.message === 'Старый пароль неверен') {
                setError('currentPassword', {
                    type: 'manual',
                    message: 'Неверный пароль',
                });
            } else {
                console.error('Failed to update password:', err);
            }

            setIsPasswordUpdated(false); // Сбрасываем флаг при ошибке
        }
    };

    return (
        <>
            <FormProvider {...methods}>
                <form className={styles.wrapper} onSubmit={handleSubmit(onSubmit)}>
                    <TextUI variant="h2">Изменить пароль</TextUI>

                    <CustomInput
                        name="currentPassword"
                        type={InputTypeEnum.Password}
                        placeholder="Текущий пароль"
                        validate={(value: string) => {
                            if (!value.trim()) {
                                return 'Введите текущий пароль';
                            }

                            return true; // Валидация пройдена
                        }}
                    />
                    <CustomInput
                        name="newPassword"
                        type={InputTypeEnum.Password}
                        placeholder="Новый пароль"
                        validate={(value: string) =>
                            value.length >= 8 || 'Пароль должен быть не менее 8 символов'
                        }
                    />
                    <CustomInput
                        name="confirmPassword"
                        type={InputTypeEnum.Password}
                        placeholder="Подтвердите пароль"
                        validate={(value: string) =>
                            value === watch('newPassword') || 'Пароли не совпадают'
                        }
                    />
                    {/* {passwordError && <p className={styles.error}>{passwordError}</p>} */}
                    {/* {error && <p className={styles.error}>Ошибка при обновлении пароля</p>} */}
                    <div className={styles.buttonWidth}>
                        <ButtonUI type="submit" label="Сохранить" variant="main" disabled={isLoading} height='42px' />
                        {isPasswordUpdated && !isMobile && !error && (
                            <div className={styles.settingsSaveBox}>
                                <LockedPadlock />
                                <p>Пароль изменен</p>
                            </div>
                        )}
                    </div>
                </form>
            </FormProvider>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                message={modalMessage}
                icon={isPasswordUpdated && <LockedPadlock scale={1.5} />}
            />
        </>
    );
};

export default ChangePasswordForm;
