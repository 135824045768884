import { useState } from "react";
import {
    useFloating,
    offset,
    flip,
    shift,
    useHover,
    useInteractions,
    FloatingPortal
} from "@floating-ui/react";
import styles from './Tooltip.module.scss';

interface TooltipProps {
    content: React.ReactNode;
    children: React.ReactNode;
}

const Tooltip = ({ content, children }: TooltipProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'top',
        middleware: [offset(5), flip(), shift()],
    });

    const hover = useHover(context);
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <>
            <FloatingPortal>
                {isOpen && (
                    <div
                        ref={refs.setFloating}
                        style={floatingStyles}
                        className={styles.tooltipContent}
                        {...getFloatingProps()}
                    >
                        {content}
                    </div>
                )}
            </FloatingPortal>
            <span
                ref={refs.setReference}
                className={styles.tooltipTrigger}
                {...getReferenceProps()}
            >
                {children}
            </span>
        </>
    );
};

export default Tooltip;
