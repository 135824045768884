import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { TextUI } from 'components/ui/TextUI/ui/TextUI';
import { CardWrapper } from 'components/CardWrapper/CardWrapper';
import LoadingIcon from 'assets/icons/loading-icon.svg';
import GreenCheckIcon from 'assets/icons/green-check.svg';
import ErrorIcon from 'assets/icons/error-icon.svg';

import styles from './EanCardCheckForm.module.scss';

interface EanCardCheckFormProps {
    onSubmit: (data: any) => void;
    onReset?: () => void;
    isLoading?: boolean;
    isPolling?: boolean;
    hasSubmitted?: boolean;
    errorMessage?: string | null;
    statusMessage?: string | null;
    isSuccess?: boolean;
    isMobile?: boolean;
}

const EanCardCheckForm: React.FC<EanCardCheckFormProps> = ({
    onSubmit,
    onReset,
    isLoading = false,
    isPolling = false,
    hasSubmitted = false,
    errorMessage = null,
    statusMessage = null,
    isSuccess = false,
    isMobile = false
}) => {
    const { register, handleSubmit, reset } = useForm();
    const [lastEanValue, setLastEanValue] = useState<string>('');

    const handleFormSubmit = (data: any) => {
        setLastEanValue(data.ean);
        onSubmit(data);
    };

    const handleReset = () => {
        setLastEanValue('');
        reset();
        if (onReset) onReset();
    };

    const renderForm = () => (
        <form onSubmit={handleSubmit(handleFormSubmit)}>
            <div className={styles.inputWrapper}>
                <input
                    type="text"
                    placeholder="EAN КАРТЫ"
                    {...register('ean', { required: true, maxLength: 13 })}
                    className={styles.input}
                    disabled={isLoading || hasSubmitted}
                    defaultValue={lastEanValue}
                />
            </div>
            <TextUI variant="bodyS" className={styles.description}>
                Укажите 13-значный код с карты, расположенный под основным номером
            </TextUI>
            {!hasSubmitted && (
                <button type="submit" className={styles.submitButton} disabled={isLoading}>
                    ОТПРАВИТЬ В БАНК
                </button>
            )}
        </form>
    );

    const renderStatus = () => {
        if (!hasSubmitted) return null;

        if (isLoading) {
            return (
                <div className={`${styles.loadingRow}`}>
                    <img
                        src={LoadingIcon}
                        alt="Загрузка"
                        className={`${styles.statusIcon} ${styles.rotating}`}
                    />
                    <span className={`${styles.approveText} ${styles.loading}`}>
                        {isPolling ? 'Проверяем статус карты...' : 'Отправка запроса...'}
                    </span>
                </div>
            );
        }

        const displayMessage = errorMessage || statusMessage || 'Произошла ошибка при проверке карты';

        return (
            <>
                <div className={`${styles.messageWrapper} ${isSuccess ? styles.approvedWrapper : styles.rejectedWrapper}`}>
                    <img
                        src={isSuccess ? GreenCheckIcon : ErrorIcon}
                        alt={isSuccess ? "Одобрено" : "Отказано"}
                        className={styles.statusIcon}
                    />
                    <span className={styles.approveText}>
                        {displayMessage}
                    </span>
                </div>
                <button onClick={handleReset} className={styles.submitButton}>
                    Отправить заново
                </button>
            </>
        );
    };

    return (
        <CardWrapper title="Проверка EAN карты" noBorder={isMobile} minWidth="512">
            {renderForm()}
            {renderStatus()}
        </CardWrapper>
    );
};

export default EanCardCheckForm;
